import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/home/banner"
import Features from "../components/home/features"
import Custody from "../components/home/custody"
import ColdStorage from "../components/home/cold-storage"
import Contact from "../components/home/contact"

const IndexPage = ({ data }) => {
  const { home } = data.markdownRemark
  
  return (
    <Layout>
      <Seo
        title={home.seoTitle}
        description={home.seoDescription}
        image={home.seoImage.publicURL}
      />

      <Banner
        image={home.bannerImage.childImageSharp}
        heading={home.bannerHeading}
        description={home.bannerDescription}
        cta={home.bannerCta}
      />

      <Features
        heading={home.featuresHeading}
        subheading={home.featuresSubeading}
        features={home.features}
      />

      <Custody
        screenshot={home.custodyScreenshot.childImageSharp}
        heading={home.custodyHeading}
        features={home.custodyFeatures}
        cta={home.custodyCta}
      />

      <ColdStorage
        heading={home.coldStorageHeading}
        subheading={home.coldStorageSubheading}
        features={home.coldStorageFeatures}
        cta={home.coldStorageCta}
        ctaLink={home.coldStorageCtaLink}
      />

      <Contact
        background={home.contactBackground.childImageSharp}
        backgroundMobile={home.contactBackgroundMobile.childImageSharp}
        heading={home.contactHeading}
        description={home.contactDescription}
        consent={home.contactConsent}
        formSubmit={home.contactFormSubmit}
        thankYouHeading={home.contactThankYouHeading}
        thankYouDescription={home.contactThankYouDescription}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/" } }) {
      home: frontmatter {
        seoTitle
        seoDescription
        seoImage {
          publicURL
        }
        bannerImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 535
              quality: 100
            )
          }
        }
        bannerHeading
        bannerDescription
        bannerCta
        featuresHeading
        featuresSubeading
        features {
          title
          icon {
            name
            childImageSharp {
              gatsbyImageData(width: 100)
            }
          }
          description
        }
        custodyScreenshot {
          childImageSharp {
            gatsbyImageData(width: 1000 quality: 100)
          }
        }
        custodyHeading
        custodyFeatures {
          title
          description
          features {
            feature
            logo {
              childImageSharp {
                gatsbyImageData(width: 100 quality: 100)
              }
            }
          }
        }
        custodyCta
        coldStorageHeading
        coldStorageSubheading
        coldStorageFeatures {
          title
          description
        }
        coldStorageCta
        coldStorageCtaLink
        contactBackground {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 1920
            )
          }
        }
        contactBackgroundMobile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 200
            )
          }
        }
        contactHeading
        contactDescription
        contactConsent
        contactFormSubmit
        contactThankYouHeading
        contactThankYouDescription
      }
    }
  }
`
