import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import ButtonLink from "../button-link"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from '../../assets'
import styled from 'styled-components'

const Banner = props => { 
  const {
    image,
    heading,
    description,
    cta
  } = props

  return (
    <BannerContent container>
      <BannerLeft item xs={12} md={6}>
        <h1>{heading}</h1>
        <p>{description}</p>
        <ButtonLink
          buttontype="red"
          to="/#get-started"
          text={cta}
          margin="30px 0 0"
        />
      </BannerLeft>
      <BannerRight item xs={12} md={6}>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={heading}
        />
      </BannerRight>
    </BannerContent>
  )
}

const BannerContent = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 40px 20px;
  background-image: linear-gradient(
    135deg,
    ${ colors.TETRA_BANNER_LIGHT } 0%,
    ${ colors.TETRA_BANNER_LIGHT } 30%,
    ${ colors.TETRA_BANNER_DARK } 70%,
    ${ colors.TETRA_BANNER_DARK } 100%
  );

  ${ mediaQueries.MD_UP } {
    height: 578px;
    padding: 0;
  }
`

const BannerLeft = styled(Grid)`
  order: 2;
  text-align: center;

  ${ mediaQueries.MD_UP } {
    order: 0;
    max-width: 710px;
    padding: 0 35px;
    text-align: left;
  }

  p {
    margin: 0;
    color: ${ colors.TETRA_WHITE };

    ${ mediaQueries.MD_UP } {
      margin: 25px 0 0;
      font-size: 24px;
      line-height: 36px;
    }
  }
`

const BannerRight = styled(Grid)`
  padding: 15px;
  text-align: center;

  ${ mediaQueries.MD_UP } {
    padding: 0 75px 0 0;
    text-align: right;
  }

  .gatsby-image-wrapper {
    display: inline-block;
    width: 100%;
    max-width: 300px;
    height: auto;

    ${ mediaQueries.MD_UP } {
      max-width: 523px;
    }
  }
`

Banner.propTypes = {
  image: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
}

export default Banner
