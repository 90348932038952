import React from 'react'
import { colors, fonts } from '../assets'
import styled from 'styled-components'

const Button = props => (
  <ButtonStyled
    title={props.text}
    buttontype={props.buttontype}
    maxwidth={props.maxWidth}
    margin={props.margin}
    padding={props.padding}
    fontSize={props.fontSize}
  >
    {props.text}
  </ButtonStyled>
)

const ButtonStyled = styled.button.attrs(({ buttontype }) => ({
  buttoncolor: buttontype === 'white' ? colors.TETRA_WHITE
    : buttontype === 'grey' ? colors.TETRA_GREY
      : buttontype === 'greyDark' ? colors.TETRA_GREY_DARK_SAFE
        : buttontype === 'red' ? colors.TETRA_RED
          : 'none',
  buttoncolortext: buttontype === 'white' ? colors.TETRA_BLACK
    : buttontype === 'grey' ? colors.TETRA_WHITE
      : buttontype === 'greyDark' ? colors.TETRA_WHITE
        : buttontype === 'red' ? colors.TETRA_WHITE
          : colors.TETRA_BLACK,
}))`
  
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  max-width: ${ props => props.maxwidth || '200px' };
  height: 50px;
  margin: ${ props => props.margin || '0' };
  padding: ${ props => props.padding || '7px 40px' };
  background: ${ props => props.buttoncolor };
  border: 1px solid ${ props => props.buttontype === 'red'
    ? props.buttoncolor
    : props.buttoncolortext
  };
  border-radius: 0;
  text-align: center;
  color: ${ props => props.buttoncolortext };
  font-family: ${ fonts.LATO };
  font-weight: 400;
  font-size: ${ props => props.fontSize || '14px' };
  line-height: 24px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
`

export default Button
