import React from "react"
import PropTypes from "prop-types"
import NumberFormat from "react-number-format"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

import Container from "../container"
import Button from "../button"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from "../../assets"
import styled from "styled-components"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

  const Contact = ({
    background,
    backgroundMobile,
    heading,
    description,
    consent,
    formSubmit,
    thankYouHeading,
    thankYouDescription,
  }) => {
  const [state, setState] = React.useState({})
  const [consentCheck, setConsentCheck] = React.useState(false)
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [formErrors, setFormErrors] = React.useState(false)

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
    setFormErrors(false)
  }

  const handleConsentCheck = (e) => {
    setConsentCheck({ consent: e.target.checked })
    setFormErrors(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target

    if (
      (state.name !== '' && state.name !== undefined) &&
      (state.email !== '' && state.email !== undefined) &&
      (state.phone !== '' && state.phone !== undefined) &&
      (state.company !== '' && state.company !== undefined) &&
      consentCheck
    ) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      })
        .then(() => setFormSubmitted(true))
        .catch((error) => alert(error))
    } else {
      setFormErrors(true)
    }
  }

  const backgroundImageSources = [
    getImage(backgroundMobile),
    {
      ...getImage(background),
      media: `(min-width: 600px)`,
    },
  ];

  return (
    <Container background={colors.TETRA_WHITE}>
      <ContactBackground image={backgroundImageSources}>
        <ContactContainer container id="get-started">
          {formSubmitted
            ? <ThankYou>
              <h2>{thankYouHeading}</h2>
              <p>{thankYouDescription}</p>
            </ThankYou>
            : <>
              <ContactDescription item xs={12} md={6}>
                <h2>{heading}</h2>
                <h3>{description}</h3>
              </ContactDescription>
              <ContactFormContent container item xs={12} md={6}>
                <ContactForm
                  name="contact"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  action="/thank-you"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <label htmlFor="botField" className="hide">
                    Don't fill this out
                  </label>
                  <input
                    type="hidden"
                    id="botField"
                    name="bot-field"
                    onChange={handleChange}
                  />
                  <p>
                    <label htmlFor="name" className="hide">Name*</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      placeholder="Name*"
                      required
                      onChange={handleChange}
                    />
                  </p>
                  <p>
                    <label htmlFor="emailInput" className="hide">Email*</label>
                    <input
                      id="emailInput"
                      type="email"
                      name="email"
                      placeholder="Email*"
                      required
                      onChange={handleChange}
                    />
                  </p>
                  <p>
                    <label htmlFor="phoneNumber" className="hide">Phone*</label>
                    <NumberFormat
                      format="+1 (###) ###-####"
                      mask="_"
                      id="phoneNumber"
                      type="text"
                      name="phone"
                      placeholder="Phone*"
                      required
                      onChange={handleChange}
                    />
                  </p>
                  <p>
                    <label htmlFor="companyName" className="hide">
                      Company
                    </label>
                    <input
                      id="companyName"
                      type="text"
                      name="company"
                      placeholder="Company*"
                      required
                      onChange={handleChange}
                    />
                  </p>
                  <p>
                    <label htmlFor="messageInput" className="hide">
                      Please describe the types and amount of digital assets you would like to custody with us
                    </label>
                    <textarea
                      id="messageInput"
                      name="message"
                      placeholder="Please describe the types and amount of digital assets you would like to custody with us"
                      onChange={handleChange}
                    />
                  </p>
                  <p>
                    <input
                      type="checkbox"
                      id="consent"
                      name="consent"
                      required
                      defaultChecked={consentCheck} 
                      onChange={handleConsentCheck}
                    />
                    <label htmlFor="consent">{consent}</label>
                  </p>
                  <p>
                    <Button
                      buttontype="red"
                      type="submit"
                      text={formSubmit}
                      disabled={formErrors && 'disabled'}
                    />
                  </p>
                  {formErrors &&
                    <Error>
                      Please fill in all required fields (marked with *).
                    </Error>
                  }
                </ContactForm>
              </ContactFormContent>
            </>
          }
        </ContactContainer>
      </ContactBackground>
    </Container>
  )
}

const ContactBackground = styled(BgImage)`
  width: 100%;
  background-position: top 0px left 0px;

  ${ mediaQueries.LG_UP } {
    height: 727px;
  }
`

const ContactContainer = styled(Grid)`
  ${ mediaQueries.CONTAINER_SIZES }
  justify-content: space-between;
  padding: 40px 20px;

  ${ mediaQueries.LG_UP } {
    padding: 70px 30px;
  }
`

const containerPadding = `
  ${ mediaQueries.MD_UP } {
    padding: 0 50px;
  }

  ${ mediaQueries.LG_UP } {
    padding: 0 120px 0 130px;
  }
`

const ThankYou = styled.div`
  ${ containerPadding };
`

const ContactDescription = styled(Grid)`
  ${ containerPadding };

  h2 {
    margin-bottom: 10px;
    
    ${ mediaQueries.MD_UP } {
      margin-bottom: 20px;
    }
  }

  h3 {
    margin-bottom: 35px;

    ${ mediaQueries.LG_UP } {
      margin-bottom: 10px;
    }
  }
`

const ContactFormContent = styled(Grid)`
  max-width: 700px;

  ${ mediaQueries.MD_UP } {
    padding: 0 45px;
  }

  ${ mediaQueries.LG_UP } {
    padding: 0 150px 0 5px;
  }
`

const ContactForm = styled.form`
  width: 100%;
  margin: 50px 0 0;
  padding: 20px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
  background: ${ colors.TETRA_WHITE };

  ${ mediaQueries.SM_UP } {
    margin: 0;
    padding: 40px;
  }

  p {
    margin-bottom: 25px;

    ${ mediaQueries.SM_UP } {
      margin-bottom: 15px;
    }
  }

  p:last-child {
    margin: 0;
  }

  label {
    display: block;
    padding: 0 0 0 30px;
    cursor: pointer;
  }

  label.hide {
    position: absolute !important;
    height: 1px; 
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
  }

  input,
  textarea,
  button {
    &:focus {
      outline: none;
    }
  }

  input,
  textarea {
    width: 100%;
    padding: 10px 20px;
    border: none;
    border-bottom: 1px solid ${ colors.TETRA_GREY };

    &:focus {
      border-bottom: 1px solid ${ colors.TETRA_GREY_DARK };
    }
  }

  input#consent {
    position: relative;
    bottom: -8px;
    width: auto;
    float: left;
    margin: 0 10px 0 0;
  }

  textarea {
    resize: none;
    min-height: 80px;
  }
`

const Error = styled.p`
  color: ${ colors.TETRA_RED };
`

Contact.propTypes = {
  background: PropTypes.object.isRequired,
  backgroundMobile: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  consent: PropTypes.string.isRequired,
  formSubmit: PropTypes.string.isRequired,
  thankYouHeading: PropTypes.string.isRequired,
  thankYouDescription: PropTypes.string.isRequired,
}

export default Contact
