import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Container from "../container"
import ButtonLink from "../button-link"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from "../../assets"
import styled from "styled-components"

const Custody = props => {
  const { screenshot, heading, features, cta } = props

  return (
    <Container background={colors.tetraGreyLight}>
      <CustodyContainer container>
        <ScreenshotContainer item xs={12} md={6}>
          <Screenshot
            image={screenshot.gatsbyImageData}
            alt={heading}
          />
        </ScreenshotContainer>
        <CustodyFeatures item xs={12} md={6}>
          <h2>{heading}</h2>
          <CustodyBullets>
            {features.map((feature, i) => (
              <div key={i}>
                <h5>{feature.title}</h5>
                <p dangerouslySetInnerHTML={{ __html: feature.description }} />
                {feature.features &&
                  <ul>
                    {feature.features.map((description, j) => (
                      <li key={j}>
                        <div>
                          <span>{description.feature}</span>
                          {description.logo &&
                            <GatsbyImage
                              image={description.logo.childImageSharp.gatsbyImageData}
                              alt={description.feature}
                            />
                          }
                        </div>
                      </li>
                    ))}
                  </ul>
                }
              </div>
            ))}
          </CustodyBullets>
          <ButtonLink
            to="/#get-started"
            text={cta}
            margin="30px 0 0"
          />
        </CustodyFeatures>
      </CustodyContainer>
    </Container>
  )
}

const CustodyContainer = styled(Grid)`
  ${ mediaQueries.CONTAINER_SIZES }
  justify-content: space-between;
  padding: 40px 20px 40px 0;

  ${ mediaQueries.SM_UP } {
    padding: 75px 30px 50px 0;
  }
`

const ScreenshotContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  ${ mediaQueries.SM_UP } {
    padding: 0 30px;
  }
`

const Screenshot = styled(GatsbyImage)`
  width: 100%;
  max-width: 725px;

  img {
    margin: 0;
  }
`

const CustodyFeatures = styled(Grid)`
  padding: 0 40px 0 20px;

  h2 {
    margin-bottom: 0;

    ${ mediaQueries.MD_DOWN } {
      margin-top: 20px;
    }
  }

  div {
    h5 {
      display: inline-block;
      width: 100%;
      margin: 20px 0 5px;
    }

    p {
      margin: 0;
    }
  }
`

const CustodyBullets = styled.div`
  ul {
    margin: 15px 0 0 18px;

    ${ mediaQueries.SM_UP } {
      margin: 10px 0 0 18px;
    }

    li {

      &:first-child {
        margin-bottom: 10px;
      }

      div {
        display: flex;
        align-items: center;
      }

      span {
        display: inline-block;
        padding: 0 8px 0 0;

        ${ mediaQueries.SM_DOWN } {
          display: block;
        }

        ${ mediaQueries.SM_UP } {
          max-width: none;
          padding: 0 8px 0 0;
        }
      }

      .gatsby-image-wrapper {
        display: inline-block;
        width: 40px;

        ${ mediaQueries.SM_DOWN } {
          display: block;
          bottom: 0;
        }
      }
    }
  }
`

Custody.propTypes = {
  screenshot: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  cta: PropTypes.string.isRequired,
}

export default Custody
