import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Container from "../container"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from "../../assets"
import styled from "styled-components"

const Features = props => {
  const { heading, subheading, features } = props

  return (
    <Container background={colors.tetraGrey}>
      <FeaturesContainer container>
        <Heading item xs={12}>
          <h2>{heading}</h2>
          <h3>{subheading}</h3>
        </Heading>
        {features.map((feature, i) => (
          <Feature key={i} container item xs={12} sm={4}>
            <Icon item xs={12}>
              <GatsbyImage
                image={feature.icon.childImageSharp.gatsbyImageData}
                alt={`${ feature.title } - Tetra Trust Company`}
                className={feature.icon.name}
              />
            </Icon>
            <Description item xs={12}>
              <h4>{feature.title}</h4>
              <p dangerouslySetInnerHTML={{__html: feature.description}} />
            </Description>
          </Feature>
        ))}
      </FeaturesContainer>
    </Container>
  )
}

const FeaturesContainer = styled(Grid)`
  ${ mediaQueries.CONTAINER_SIZES }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 35px 20px 30px;

  ${ mediaQueries.SM_UP } {
    padding: 60px 30px;
  }

  ${ mediaQueries.MD_UP } {
    justify-content: flex-start;
  }
`

const Heading = styled(Grid)`
  text-align: center;

  ${ mediaQueries.SM_UP } {
    margin: 0 0 50px;
  }

  h2 {
    max-width: 290px;
    margin: 0 auto 5px;

    ${ mediaQueries.SM_UP } {
      max-width: none;
      margin: 0 auto 10px;
    }
  }

  h3 {
    margin: 0;

    ${ mediaQueries.SM_UP } {
      font-size: 24px;
      line-height: 32px;
    }
  }
`

const Feature = styled(Grid)`
  max-width: 320px;
  min-height: 158px;
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;

  ${ mediaQueries.SM_UP } {
    min-height: 215px;
  }
`

const Icon = styled(Grid)`
  height: 103px;
  padding-top: 35px;

  ${ mediaQueries.SM_UP } {
    height: 112px;
    padding-top: 20px;
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
  }

  .feature-regulated {
    width: 56px;
    height: 56px;

    ${ mediaQueries.SM_UP } {
      width: 72px;
      height: 72px;
    }
  }

  .feature-secure {
    width: 45px;
    height: 56px;
    
    ${ mediaQueries.SM_UP } {
      width: 58px;
      height: 72px;
    }
  }

  .feature-reliable {
    width: 52px;
    height: 56px;

    ${ mediaQueries.SM_UP } {
      width: 67px;
      height: 72px;
    }
  }
`

const Description = styled(Grid)`
  h4 {
    ${ mediaQueries.SM_DOWN } {
      margin-bottom: 3px;
    }
  }

  p {
    max-width: 270px;
    margin: 0 auto;
    line-height: 24px;
  }
`

Features.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
}

export default Features
