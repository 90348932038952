import React from "react"
import PropTypes from "prop-types"
import Container from "../container"
import ButtonLink from "../button-link"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from "../../assets"
import styled from "styled-components"

const ColdStorage = props => {
  const { heading, subheading, features, cta, ctaLink } = props

  return (
    <Container background={colors.TETRA_BLACK}>
      <ColdStorageContainer container>
        <ColdStorageDescription item xs={12} md={6}>
          <h2>{heading}</h2>
          <h3>{subheading}</h3>
        </ColdStorageDescription>
        <ColdStorageFeatures item xs={12} md={6}>
          {features.map((feature, i) => (
            <div key={i}>
              <h5>{feature.title}</h5>
              <p>{feature.description}</p>
            </div>
          ))}
          <ButtonLink
            buttontype="white"
            to={ctaLink}
            text={cta}
            margin="10px 0 0"
          />
        </ColdStorageFeatures>
      </ColdStorageContainer>
    </Container>
  )
}

const ColdStorageContainer = styled(Grid)`
  ${ mediaQueries.CONTAINER_SIZES }
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;

  ${ mediaQueries.MD_UP } {
    padding: 70px 30px;
  }

  h2,
  h3,
  h5,
  p {
    color: ${ colors.TETRA_WHITE };
  }
`

const ColdStorageDescription = styled(Grid)`
  ${ mediaQueries.MD_UP } {
    padding: 0 50px;
  }

  ${ mediaQueries.LG_UP } {
    padding: 0 120px 0 130px;
  }

  h2 {
    margin-bottom: 10px;
    
    ${ mediaQueries.MD_UP } {
      margin-bottom: 20px;
    }
  }

  h3 {
    margin-bottom: 35px;

    ${ mediaQueries.MD_UP } {
      margin-bottom: 10px;
    }
  }
`

const ColdStorageFeatures = styled(Grid)`
  ${ mediaQueries.MD_UP } {
    padding-left: 5px;
  }

  h5 {
    margin-bottom: 5px;

    ${ mediaQueries.MD_UP } {
      margin-bottom: 10px;
    }
  }
`

ColdStorage.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  cta: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
}

export default ColdStorage
